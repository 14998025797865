<template>
    <div>
      <div v-if="isLoading">
        <OtherLoading />
      </div>
      <div v-else>
        <h4 class="mr-sm-4 mb-3 header-tablepage">REP - Repair Product</h4>
  
        <b-tabs content-class="py-3" v-model="tabIndex">
          <b-tab
            v-for="status of statusList"
            :key="status.id"
            :value="status.id"
            @click="onTabChange(status.id, $event)"
          >
            <template #title>
              {{ status.name }}
              <b-badge pill>
                {{ status.count }}
                <span class="sr-only">count items list</span></b-badge
              >
            </template>
            <div class="d-flex justify-content-between mb-2">
              <InputText
                v-model="filter.search"
                type="text"
                faIcon="search"
                className="mb-2 bg-second w-50"
                placeholder="ค้นหาเลขที่เอกสาร"
                @onClickIcon="onSearch"
                @onEnter="onSearch"
              />
              <b-button
                class="bg-main bt-height"
                :disabled="isLoading"
                @click="goDetail(0)"
                ><font-awesome-icon icon="plus" class="mr-0 mr-sm-1" />
                สร้าง
              </b-button>
            </div>
            <b-table
              striped
              hover
              :busy="isBusy"
              :items="items"
              :fields="fields"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(request_branch_name)="{ item }">
                <div class="text-center text-black my-2">
                  {{ item.request_branch_code }} {{ item.created_by_name }}
                  <br />
                  <span class="text-gray">{{ item.request_branch_name }}</span>
                </div>
              </template>
              <template v-slot:cell(rqp_status_name)="{ item }">
                <div
                  :class="{
                    'text-success': item.rqp_status_id == 3,
                    'text-gray': item.rqp_status_id == 1,
                    'text-warning': item.rqp_status_id == 2,
                  }"
                >
                  {{ item.status_display || "-" }}
                </div>
              </template>
              <template v-slot:cell(note)="{ item }">
                {{ item.note || "-" }}
              </template>
              <template v-slot:cell(rqp_no)="{ item }">
                <a
                  class="text-underline my-2"
                  :href="`/request-purchase/detail/${item.rqp_id}`"
                >
                  {{ item.rqp_no }}
                </a>
              </template>
              <template v-slot:cell(created_time)="data">
                <div class="text-center text-black my-2">
                  {{ $moment(data.item.created_time).format("DD/MM/YYYY") }}
                  <br />
                  {{ $moment(data.item.created_time).format("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div
                  class="d-flex align-items-center justify-content-center"
                  v-if="data.item.rqp_status_id == 1"
                >
                  <b-dropdown
                    no-caret
                    ref="dropdown"
                    class=""
                    variant="link"
                    right
                  >
                    <template #button-content>
                      <font-awesome-icon
                        icon="ellipsis-v"
                        title="detail"
                        class="text-black pointer"
                      />
                    </template>
  
                    <b-dropdown-item
                      v-if="data.item.rqp_status_id == 1"
                      @click="
                        $router.push(
                          `/request-purchase/detail/${data.item.rqp_id}`
                        )
                      "
                    >
                      แก้ไข
                    </b-dropdown-item>
                  </b-dropdown>
                </div></template
              >
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
            <Pagination
              @handleChangeTake="handleChangeTake"
              :pageOptions="pageOptions"
              :filter="filter"
              :rows="rows"
              @pagination="pagination"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </template>
  
  <script>
  import OtherLoading from "@/components/loading/OtherLoading";
  import HeaderPanel from "@/components/HeaderPanel";
  import Pagination from "@/components/Pagination";
  import InputText from "@/components/inputs/InputText";
  
  import { mapGetters } from "vuex";
  
  export default {
    components: {
      OtherLoading,
      Pagination,
      HeaderPanel,
      InputText,
    },
    name: "Inventory",
    data() {
      return {
        fields: [
          {
            key: "rqp_no",
            label: "หมายเลขคำขอ",
            thStyle: { width: "10%" },
          },
          {
            key: "note",
            label: "หมายเหตุ",
            thStyle: { width: "20%" },
          },
  
          {
            key: "product_count",
            label: "จำนวนรายการสินค้า",
            thStyle: { width: "15%" },
          },
          {
            key: "request_branch_name",
            label: "พนักงาน/สาขา",
            thStyle: { width: "25%" },
          },
          {
            key: "created_time",
            label: "วันที่/เวลา",
            thStyle: { width: "20%" },
          },
          {
            key: "rqp_status_name",
            label: "สถานะ",
            tdClass: "text-center",
            thStyle: { width: "20%" },
          },
          {
            key: "action",
            label: "Action",
            tdClass: "text-center",
            thStyle: { width: "10%" },
          },
        ],
        items: [],
        isBusy: false,
        rows: 0,
        showing: 1,
  
        filter: {
          search: "",
          page: 1,
          take: 10,
          rqp_status: [0],
        },
        tabIndex: 0,
        pageOptions: [
          { value: 5, text: "5 / page" },
          { value: 10, text: "10 / page" },
          { value: 30, text: "30 / page" },
          { value: 50, text: "50 / page" },
          { value: 100, text: "100 / page" },
        ],
  
        isLoading: true,
        statusList: [],
      };
    },
    computed: {
      ...mapGetters({
        branchId: "getBranchId",
      }),
    },
    created: async function () {
      await this.getList(0);
      await this.getStatus();
    },
    methods: {
      getList: async function () {
        this.isBusy = true;
        let payload = { ...this.filter };
  
        await this.axios
          .post(`${this.$baseUrl}/rqp/list`, payload)
          .then((data) => {
            this.isLoading = false;
            if (data.result == 1) {
              this.items = data.detail.rqp_list_response;
              this.rows = data.detail.total_count;
            }
          });
  
        this.isBusy = false;
      },
      getStatus: async function () {
        this.isBusy = true;
        let payload = { ...this.filter };
  
        await this.axios
          .post(`${this.$baseUrl}/rqp/status`, payload)
          .then((data) => {
            this.isLoading = false;
            if (data.result == 1) {
              this.statusList = data.detail;
            }
          });
  
        this.isBusy = false;
      },
      handleSearch(value) {
        this.pagination(1);
      },
      pagination(page) {
        this.filter.page = page;
        this.$emit("filterPage", this.filter);
        this.getList();
      },
      handleChangeTake(value) {
        this.filter.page = 1;
        this.filter.take = value;
        this.$emit("filterPage", this.filter);
        this.getList();
      },
  
      onTabChange(val, e) {
        this.filter.rqp_status = [val];
        this.filter.page = 1;
        this.getList();
      },
      onSearch() {
        this.getList();
      },
      goDetail(id) {
        this.$router.push(`/repair-product/detail/${id}`);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .bt-height {
    height: 35px !important;
  }
  .header-tablepage {
    color: var(--primary-color) !important;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }
  
  .text-underline {
    text-decoration: underline;
    cursor: pointer;
  }
  </style>
  